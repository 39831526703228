import React from 'react'
import FAQ from '../FAQ'
function index() {
  return (
    <div className="faqs">
    <h1>Frequently Asked Questions</h1>
    <h2>If you have a question that is not listed here please call  AV System Integrators LLC at (305) 703-3248 now!</h2>
    <FAQ />
  </div>
  )
}

export default index;
