import React from 'react'

function index() {
  return (
    <div className="brands">
      <center><h1>404 ERROR</h1><h2>Page Not Found!</h2></center>
    </div>
  )
}

export default index
