import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import testimonialsData from '../TestiData';

const useStyles = makeStyles({
  card: {
    maxWidth: 800,
    margin: '20px auto',
    padding: 10,
    textAlign: 'center',
  },
  media: {
    height: 200,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '0 auto',
  },
});

const Testimonials = () => {
  const classes = useStyles();

  return (
    <div>
      {testimonialsData.map((testimonial) => (
        <Card className={classes.card} key={testimonial.id}>     
          <CardContent>
            <Typography variant="h6">{testimonial.name}</Typography>
            <Typography>{testimonial.message}</Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Testimonials;