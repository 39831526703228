import React from 'react'
function index() {
  return (
    <div className="cont">
      <div className="contact">
         <div className="container">
            <div className="row ">
               <div className="col-md-6">
                  <div className="titlepage text_align_left">
                     <h2>Get In Touch</h2>
                  </div>
                  <form action="https://formspree.io/f/xdoragdq" method="POST" id="request" className="main_form">
                     <div className="row">
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Name" type="type" name="Name"/> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Phone Number" type="type" name="Phone Number"/>                          
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Email" type="type" name="Email"/>                          
                        </div>
                        <div className="col-md-12">
                           <textarea className="textarea" placeholder="Message" type="type" Message="Name"></textarea>
                        </div>
                        <div className="col-md-12">
                           <button className="send_btn" type="submit" value="send">Send Now</button>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="col-md-6">
                  <div className="titlepage text_align_left">
                     <h2>Clients Comments</h2>
                  </div>
                  <div id="clientsl" className="carousel slide our_clientsl" data-ride="carousel">
                     <ol className="carousel-indicators">
                        <li data-target="#clientsl" data-slide-to="0" className="active"></li>
                        <li data-target="#clientsl" data-slide-to="1"></li>
                        <li data-target="#clientsl" data-slide-to="2"></li>
                     </ol>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <div className="container">
                              <div className="carousel-caption posi_in">
                                 <div className="clientsl_text">                                    
                                    <h3>Jacky<img src="images/icon.png" alt="#"/></h3>
                                    <p>AVSI LLC is very professional. Their work is top notch and very neat. I look forward to continue working together.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="container">
                              <div className="carousel-caption posi_in">
                                 <div className="clientsl_text">
                                    <h3>Donovan<img src="images/icon.png" alt="#"/></h3>
                                    <p>I thank AVSI LLC for being an honest and knowledgeable Company. They installed my surround sound, cctv and installed recess lights with smart switches, Connected to my amazon Alexa. I wasn't surprised how competent and knowledgeable there technicians are. AVSI LLC came highly recommend. There technician Paul was awesome. I will definitely recommend them to anyone because they goes above and beyond to please the customer</p>
                                 </div>
                              </div>
                           </div>
                        </div>                        
                     </div>
                     <a className="carousel-control-prev" href="#clientsl" role="button" data-slide="prev">
                     <i className="fa fa-angle-left" aria-hidden="true"></i>
                     <span className="sr-only">Previous</span>
                     </a>
                     <a className="carousel-control-next" href="#clientsl" role="button" data-slide="next">
                     <i className="fa fa-angle-right" aria-hidden="true"></i>
                     <span className="sr-only">Next</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}

export default index
