import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" className="service-details">
        <div className="container">
        <div className="row gy-5">
            <div className="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <img src="images/projectmanagement.jpg" alt="projectmanagement" className="img-fluid services-img"/>
            <h3 style={{textAlign:"center"}}>Project Management</h3>
            <p>
            The role of a project manager for your home's construction or renovation project,  such as a Crestron SmartHome automation installation, is critical. Once you have worked through all the details with our sales and design team, you will have created the blueprint for an amazing system. In an ideal situation, the work begins at the ground breaking. However, we can retrofit for upgrades or remodeling projects as well.
            </p>
            <p>
            Folks may not realize how just many tradesman and other professionals are involved in every single home project. From the first shovel of dirt to that last moment of cleaning, every trade will have touched your new system.  Each team will need direction and guidance on how to work together to insure a successful installation. It is not easy to manage a highly technical project and be able to speak the industry language of the tradesmen, architects and designers, but you can rely on us to do it. We are great at conveying our client's needs and taking an active role in the smart home project management methodologies to insure the job is done correctly.
            </p>
            <p>
            Not having a designated project manager can mean disaster. A project manager oversees all aspects of your construction, from start to completion. Our team is specially trained and certified by each manufacturer and must keep current with new developments. This is important because the technology industry is in perpetual motion, offering a plethora of upgrades and new products.
            </p>
            <p>
            Having great smart home project management in place does not guarantee that there won't be bumps in the road during your automation system installation, but it does mean that you have started out on the right foot. Your team will always represent you and make sure that your project's needs are being met.
            </p>
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index
