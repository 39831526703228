const testimonialsData = [
    {
      id: 1,
      name: "Jacky",
      message: "AVSI LLC is very professional. Their work is top notch and very neat. I look forward to continue working together.",
    },
    {
      id: 2,
      name: "Donovan",
      message: "I thank AVSI LLC for being an honest and knowledgeable Company. They installed my surround sound, cctv and installed recess lights with smart switches, Connected to my amazon Alexa. I wasn't surprised how competent and knowledgeable there technicians are. AVSI LLC came highly recommend. There technician Paul was awesome. I will definitely recommend them to anyone because they goes above and beyond to please the customer",
    },
    // Add more testimonials as needed
  ];
  
  export default testimonialsData;
  