import React from 'react'
import {Link} from 'react-router-dom'
function index() {
  return ( 
      <footer>
      <div id="footer" className="footer">       
                <div className="footer-static-middle">
                    <div className="container">
                        <div className="footer-logo-wrap pt-50 pb-35">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-logo">
                                        <img src="images/logo.png" alt="Footer Logo"></img>
                                        <p className="info">
                                        we specialize in transforming houses into immersive entertainment places and seamlessly integrated smart homes. Our dedicated team of experts combines expertise in audiovisual design, automation systems, and custom installations to deliver personalized solutions that exceed your expectations. 
                                        </p>
                                    </div>
                                    <ul className="des">
                                        <li>
                                            <span>Address: </span>
                                            Pembroke Pines, Florida, United States
                                        </li>
                                        <li>
                                            <span>Phone: </span>
                                            <Link to="#"> (305) 703-3248, </Link>
                                            <Link to="#"> (786) 632-2034 </Link>
                                        </li>
                                        
                                        <li>
                                            <span>Email: </span>
                                            <Link to="mailto://customerservice@avsillc.net">customerservice@avsillc.net</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <div className="footer-block">
                                        <h3 className="footer-block-title">Services</h3>
                                        <ul>
                                            <li><Link to="/home-theater-design">Home Theater Design</Link></li>
                                            <li><Link to="/audio-installations">Audio Installations</Link></li>
                                            <li><Link to="/video-installations">Video Installations</Link></li>                                           
                                            <li><Link to="/home-automation">Home Automation</Link></li>                                           
                                            <li><Link to="/project-management">Project Management</Link></li>
                                        
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <div className="footer-block">
                                        <h3 className="footer-block-title">Our company</h3>
                                        <ul>
                                            <li><Link to="/about">About us</Link></li>
                                            <li><Link to="https://www.google.com/maps/place/Florida,+USA/@28.3941694,-83.0789579,7z/data=!4m6!3m5!1s0x88c1766591562abf:0xf72e13d35bc74ed0!8m2!3d27.6648274!4d-81.5157535!16zL20vMDJ4cnk?entry=ttu">Location</Link></li>
                                            <li><Link to="/services">Services</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="footer-block">
                                        <h3 className="footer-block-title">Follow Us</h3>
                                        <ul className="social-link">                                           
                                            <li className="facebook">
                                                <Link to="https://www.facebook.com/AvSystemsIntegrators/" data-toggle="tooltip" target="_blank" title="Facebook">
                                                    <i className="fa fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li className="youtube">
                                                <Link to="https://youtube.com/@Av_system_integrators" data-toggle="tooltip" target="_blank" title="Youtube">
                                                    <i className="fa fa-youtube"></i>
                                                </Link>
                                            </li>
                                            <li className="instagram">
                                                <Link to="https://www.instagram.com/av_system_integrators/" data-toggle="tooltip" target="_blank" title="Instagram">
                                                    <i className="fa fa-instagram"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer-newsletter">
                                        <h4>Sign up to newsletter</h4>
                                        <form action="#" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="footer-subscribe-form validate" target="_blank" novalidate>
                                           <div id="mc_embed_signup_scroll">
                                              <div id="mc-form" className="mc-form subscribe-form form-group" >
                                                <input id="mc-email" type="email" autocomplete="off" placeholder="Enter your email" />
                                                <button  className="btn" id="mc-submit">Subscribe</button>
                                              </div>
                                           </div>
                                        </form>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <center>
                        <p>© 2023 All Rights Reserved. <a href="https://www.facebook.com/Jenocode">Jenocode</a></p>
                     </center>
                </div>
            </div>
      </footer>
  )
}
export default index;