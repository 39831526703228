import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" className="service-details">
        <div className="container">
        <div className="row gy-5">
            <div className="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <img src="images/homeautomation.jpg" alt="homeautomation" className="img-fluid services-img"/>
            <h3 style={{textAlign:"center"}}>Home Automation</h3>
            <p>
            Every wanted to ditch all the different controllers for the multiple devices in your house? AV System Integrators LLC can install an all-in-one touch-screen device from a top manufacturer like Crestron. This will take the place of virtually every button and switch inside your home, turning your house into a smart home of the future. Just imagine being able to turn your lights on, adjust your music's volume, and check in on your home's security all with a single network device. AV System Integrators LLC is a manufacturer certified installer for Crestron among other prominent home automation brands and we can install a variety of tasks to either wall-mounted touch-screen displays or a mobile tablet you can maneuver around the house. Our home automation installations include:
            </p>
            <h4>Our list of home automation installations include:</h4>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>Lighting control throughout the house, including dimming</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Activation and volume control for your audio speaker systems</span></li>
                <li><i className="bi bi-check-circle"></i> <span>The ability to turn on, monitor, and change what is being shown on any given TV set in the house</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Lock and unlock doors and windows from inside the house or when you're out and about</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Monitor home security systems in real time, and view live feeds from security cameras from any location</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Turn on devices that are typically activated by a wall switch, such as warming the spa or pool</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Adjust indoor temperature for your optimum comfort for when you get home or while you're away</span></li>
            </ul>
            <p>
            With mobile app integration, all tasks that are performed in the home via the touch-screen devices can also be performed anywhere in the world via your smartphone or tablet. Complete control of your home is always at the palm of your hand. The mobile app is helpful to anyone who is ever in a hurry, consistently busy, or simply curious about the condition of their home at any given time.
            </p>
            <p>
            Our home automation installation service has quintessentially eliminated the need traditional switches and buttons around the house while at the same time streamlining the process of performing some of the most basic tasks from anywhere rather than physically going from room to room. It’s not just for rich folks anymore; it’s for the modern smart house going into the future.
            </p>
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index
