import React from 'react'
import {Link} from 'react-router-dom'
function index() {
  return (
    <div>
      <section id="gallery" className="gallery">
      <div className="container-fluid">
      <center><h2>Gallery</h2></center>
        <div className="row gy-4 justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-1.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-1.jpg" title="Gallery 1" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
               </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-2.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-2.jpg" title="Gallery 2" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-3.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-3.jpg" title="Gallery 3" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-4.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-4.jpg" title="Gallery 4" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-5.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-5.jpg" title="Gallery 5" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-6.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-6.jpg" title="Gallery 6" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-7.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-7.jpg" title="Gallery 7" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-8.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-8.jpg" title="Gallery 8" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-9.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-9.jpg" title="Gallery 9" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-10.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-10.jpg" title="Gallery 10" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-11.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-11.jpg" title="Gallery 11" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-12.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-12.jpg" title="Gallery 12" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-13.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-13.jpg" title="Gallery 13" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-14.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-14.jpg" title="Gallery 14" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-15.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-15.jpg" title="Gallery 15" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 gird">
            <div className="gallery-item h-100">
              <img src="/images/gallery-16.jpg" className="img-fluid" alt=""/>
              <div className="gallery-links d-flex align-items-center justify-content-center">
                <Link to="/images/gallery-16.jpg" title="Gallery 16" className="glightbox preview-link"><i className="bi bi-arrows-angle-expand"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    </div>
  )
}

export default index
