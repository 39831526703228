import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
function index() {
  return (
   <Fragment>
    <header>
         <div id="header" className="header">
            <div className="container-fluid">
               <div className="row d_flex">
                  <div className=" col-md-2 col-sm-3 col logo_section">
                     <div className="full">
                        <div className="center-desk">
                           <div className="logo">
                              <Link to="/"><img src="images/logo.png" alt="#" /></Link>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-8 col-sm-9">
                     <nav className="navigation navbar navbar-expand-md navbar-dark ">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarsExample04">
                           <ul className="navbar-nav mr-auto">
                              <li className="nav-item active">
                                 <Link className="nav-link" to="/">Home</Link>
                              </li>
                              <li className="nav-item dropdown">
                                 <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                     Services
                                 </Link>
                                 <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                 <Link className="dropdown-item" to="/home-theater-design">Home Theater Design</Link>
                                 <div className="dropdown-divider"></div>
                                 <Link className="dropdown-item" to="/audio-installations">Audio Installations</Link>
                                 <div className="dropdown-divider"></div>
                                 <Link className="dropdown-item" to="/video-installations">Video Installations</Link>
                                 <div className="dropdown-divider"></div>
                                 <Link className="dropdown-item" to="/home-automation">Home Automation</Link>
                                 <div className="dropdown-divider"></div>
                                 <Link className="dropdown-item" to="/project-management">Project Management</Link>
                                 </div>
                               </li>
                              <li className="nav-item">
                                 <Link className="nav-link" to="/brands">Brands</Link>
                              </li>
                              <li className="nav-item">
                                 <Link className="nav-link" to="/about">About</Link>
                              </li>                              
                              <li className="nav-item">
                                 <Link className="nav-link" to="/testimonials">Testimonials</Link>
                              </li>     
                              <li className="nav-item">
                                 <Link className="nav-link" to="/gallery">Gallery</Link>
                              </li>                         
                              <li className="nav-item">
                                 <Link className="nav-link" to="/faq">FAQ's</Link>
                              </li>
                              <li className="nav-item">
                                 <Link className="nav-link" to="/contact">Contact</Link>
                              </li>
                           </ul>
                        </div>
                     </nav>
                  </div>          
               </div>
            </div>
         </div>
      </header>
      </Fragment>
  )
}

export default index;
