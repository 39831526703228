import React from 'react'
import Testimonials from '../Testimonials'
function index() {
  return (
    <div className="testi">
      <h1>Testimonials</h1>
      <Testimonials/>
    </div>
  )
}

export default index
