import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" class="service-details">
        <div class="container">
        <div class="row gy-5">
            <div class="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <img src="images/videoinstallation.jpg" alt="videoinstallation" class="img-fluid services-img"/>
            <h3 style={{textAlign:"center"}}>Home Video Installations</h3>
            <p>
            When choosing a big screen for your home theater forum, it is important to choose the one that best suits your needs and lifestyle. Are you a cinema buff? Avid sports fan? Perhaps you just want a comfy place to sit will you catch up on the latest show to which you’ve become addicted. Whatever kind of viewer you are, we have a plethora of options for your home theater video installations. HDTVs, more often known as flat screen TVs, offer optimal viewing quality in terms of color quality, luminosity, and detail. HDTVs can be arranged during your home theater installation by wall mounting or table top stands depending on your preference.
            </p>
            <h4>Our list of Home Video Installations include:</h4>
            <ul>
                <li><i class="bi bi-check-circle"></i> <span>LED- Utilizing back-lit technology, these HDTVs are the latest in flat panel operating technology, with LED diode lighting for optimal color and movement quality; they are considered the best choice if you have the cash for them</span></li>
                <li><i class="bi bi-check-circle"></i> <span>LCD- Also using a back-light system, these devices use cold cathode fluorescent lights (CCFL's) to illuminate their liquid crystal screens, and tend to be cheaper that LED HDTVs</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Plasma- The original kings of flat screens technology, these classics illuminate the liquid crystals without backlighting to display images, and are the most budget friendly</span></li>
                <li><i class="bi bi-check-circle"></i> <span>3D HD- Bring the power of 3D movies to your home theater with augmented screen technology that allows you to let the images literally pop out of your home screen; these screens can display 2D images just as well</span></li>
            </ul>
            <p>
            Size, thickness, and pixel resolution (720p, 1080p, etc.) can vary between the manufacturers and models. When it’s time for video installations, we’ll be there to help you choose the screen that best suits your needs and your budget. Want the latest in HD TV technology? New 4K Ultra HDTV sets offered by LG, Samsung, Sony, Sharp, and other major brands provide 3840 X 2160 pixel resolution, four times the resolution of 1080p HDTV's. These sets are designed to not lose details as the images get bigger on the screen, making them ideal for home theater systems. These latest HDTVs are the best screen display option for the movie watcher or sports fans looking for fluid movement and picture quality. 
            </p>
            <h4>Projectors</h4>
            <p>
            If you want the ultimate home theater experience, we can install a home theater projector rather than a screen to get that real movie theater feel. Modern projectors use digital technology to display images, assuring that no matter the surface they shine on (though actual projector screens yield the best results), you will get perfect picture quality from you shows and movies.
            </p>
            <p>
            Projectors can be installed in a variety of ways, depending on your home theater setup. Mounting the home theater projector to your back wall or ceiling is a good choice because it puts the projector up and out of the way while maintaining a good angle for displaying images. They can also be placed on flat surfaces such as tables and bookshelves in case you would like to point the display at another wall or a lower angle.
            </p>
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index
