import React from 'react'
import AppRoutes from '../Routes'

function index() {
  return (
      <AppRoutes/>
  )
}

export default index;
