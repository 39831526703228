import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Home from '../Home'
import Services from '../Services'
import Brands from '../Brands'
import About from '../About'
import MainTesti from '../MainTesti'
import Gallery from '../Gallery'
import MainFAQ from '../MainFAQ'
import Contact from '../Contact'
import HomeTheaterDesign from '../HomeTheaterDesign'
import HomeAutomation from '../HomeAutomation'
import AudioInstallations from '../AudioInstallations'
import VideoInstallations from '../VideoInstallations'
import ProjectManagement from '../ProjectManagement'
import Error404 from '../Error404'
function index() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/services" element={<Services/>}></Route> 
      <Route path="/home-theater-design" element={<HomeTheaterDesign/>}></Route> 
      <Route path="/audio-installations" element={<AudioInstallations/>}></Route> 
      <Route path="/video-installations" element={<VideoInstallations/>}></Route> 
      <Route path="/home-automation" element={<HomeAutomation/>}></Route> 
      <Route path="/project-management" element={<ProjectManagement/>}></Route> 
      <Route path="/brands" element={<Brands/>}></Route>  
      <Route path="/about" element={<About/>}></Route>
      <Route path="/testimonials" element={<MainTesti/>}></Route>
      <Route path="/gallery" element={<Gallery/>}></Route>
      <Route path="/faq" element={<MainFAQ/>}></Route>
      <Route path="/contact" element={<Contact/>}></Route>  
      <Route path="*" element={<Error404/>}></Route>      

    </Routes>
  )
}

export default index;

