import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" className="service-details">
        <div className="container">
        <div className="row gy-5">
            <div className="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <h3 style={{textAlign:"center"}}>SERVICES</h3>           
            <h4>Our List Of Services</h4>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>Home Theater Design</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Audio Installations</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Video Installations</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Home Automation</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Project Management</span></li>
            </ul>            
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index;
