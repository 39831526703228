import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" className="service-details">
        <div className="container">
        <div className="row gy-5">
            <div className="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <img src="images/hometheaterdesign.jpg" alt="hometheaterdesign" className="img-fluid services-img"/>
            <h3 style={{textAlign:"center"}}>Home Theater Design</h3>
            <p>
            Are you building a new house and looking to turn your traditional media room into your own home theater? How about renovating your home to fit your particular vision with completely customized interior designs? Whether you are building the room from scratch or just updating an existing one, we can make your vision come to life. Our certified home theater design specialists are well-qualified in installing an array of interior décor and electronic fixtures that can really make any home feel like a mansion with modern interior design.
            </p>
            <h4>Our list of home theater design installations include:</h4>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>High end audio installation of surround sound speakers to become totally immersed in your media, including wireless surround sound</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Video installation of flat panel HDTVs or projectors for crisp picture quality</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Adjustable lighting that can fade in and out for that movie theater feel</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Automated shades that block out incoming outdoor light through windows</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Temperature control to keep you comfortable while watching your movie, TV show, or favorite sports game</span></li>
                <li><i className="bi bi-check-circle"></i> <span>Custom home entertainment furniture, ranging from comfy couches to traditional flip-down home theater seating</span></li>
            </ul>
            <p>
            By opting for home theater installation, you can experience larger-than-life quality of picture and sound while avoiding sticky floors, crowded seating, and annoyingly noisy patrons. Why not have a night out at a theater that's within walking distance of the bedroom, or if you so choose, while relaxing in your custom built spa? Besides, it definitely adds value to your home!
            </p>
            <p>
            However, before you start, it's always best to be sure everything is installed properly by professionals. We have over 20 years of success under our belt in creating unique experiences for all of our customers. AV System Integrators LLC is a manufacturer-certified and trusted name when it comes to delivering unsurpassed quality in the Plano and Dallas areas. We will work with you to create a building plan that will fit your vision and you budget.
            </p>
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index
