import React from 'react'
function index() {
  return (
   <div className="ab">
         <div className="about">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="titlepage text_align_center">
                     <h2>About Company</h2>
                     <p>Welcome to AVSI, your premier destination for exceptional home theater and smart home services. With a passion for cutting-edge technology and a commitment to unparalleled customer satisfaction, we specialize in transforming houses into immersive entertainment havens and seamlessly integrated smart homes. Our dedicated team of experts combines expertise in audiovisual design, automation systems, and custom installations to deliver personalized solutions that exceed your expectations.
                        At AVSI, we understand that every client has unique preferences and requirements. That's why we offer tailor-made home theater designs that harmonize striking aesthetics, superior acoustics, and state-of-the-art equipment. From initial concept and meticulous planning to flawless execution, our team collaborates closely with you to bring your vision to life. Whether you desire a classNameic cinematic experience or a contemporary, cutting-edge setup, we ensure optimal audiovisual performance, immersive seating arrangements, and intelligent control systems that effortlessly enhance your entertainment enjoyment.
                        </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </div>
  )
}

export default index;
