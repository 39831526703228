import React from 'react'

function index() {
  return (
    <div className="brands">
      <center><h1>Comming Soon</h1></center>
    </div>
  )
}

export default index
