import React from 'react'
import {Link} from 'react-router-dom'
function index() {
  return (
    <div>
      <div id="top_section" className="banner_main">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div id="myCarousel" className="carousel slide" data-ride="carousel">
                     <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>                        
                     </ol>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <div className="container-fluid">
                              <div className="carousel-caption relative">
                                 <div className="bluid">
                                    <h1>Custom Home<br/>Theater Design</h1>
                                    <p>We can install top of the line surround sound systems so you feel <br/> like you are in the middle of the action.
                                    </p>
                                    <Link className="read_more" to="/about">About</Link><Link className="read_more" to="/contact">Contact Us</Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="container-fluid">
                              <div className="carousel-caption relative">
                                 <div className="bluid">
                                    <h1>Home<br/>Automation</h1>
                                    <p>While optimizing your custom home theater with <br/> the latest smart home technology</p>
                                    <Link className="read_more" to="/about">About</Link><Link className="read_more" to="/contact">Contact Us</Link>                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="container-fluid">
                              <div className="carousel-caption relative">
                                 <div className="bluid">
                                    <h1>Project<br/>Management</h1>
                                    <p>Building your dream smart home from scratch or <br/> through complete renovation?</p>
                                    <Link className="read_more" to="/about">About</Link><Link className="read_more" to="/contact">Contact Us</Link>                                    
                                 </div>
                              </div>
                           </div>
                        </div>                      
                     </div>
                     <Link className="carousel-control-prev" to="#myCarousel" role="button" data-slide="prev">
                     <i className="fa fa-angle-left" aria-hidden="true"></i>
                     <span className="sr-only">Previous</span>
                     </Link>
                     <Link className="carousel-control-next" to="#myCarousel" role="button" data-slide="next">
                     <i className="fa fa-angle-right" aria-hidden="true"></i>
                     <span className="sr-only">Next</span>
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="we_do">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="titlepage text_align_center">
                     <h2>What We Do </h2>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-12">
                  <div id="we1" className="carousel slide" data-ride="carousel">
                     <ol className="carousel-indicators">
                        <li data-target="#we1" data-slide-to="0" className="active"></li>
                        <li data-target="#we1" data-slide-to="1"></li>
                        <li data-target="#we1" data-slide-to="2"></li>
                        <li data-target="#we1" data-slide-to="3"></li>
                     </ol>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <div className="container-fluid">
                              <div className="carousel-caption we1_do">
                                 <div className="row">
                                    <div className="col-md-4">
                                       <div id="bo_ho" className="we_box .text_align_center">
                                          <i><img src="images/service1.jpg" alt="#"/></i>
                                          <h3>Custom Home<br/>Theater Design</h3>
                                          <p>Want to become totally immersed in your movies? We can install top of the line surround sound systems so you feel like you are in the middle of the action.</p>
                                          <Link className="read_more" to="/home-theater-design">Read More</Link>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div id="bo_ho" className="we_box text_align_center">
                                          <i><img src="images/service2.jpg" alt="#"/></i>
                                          <h3>Home<br/>Automation</h3>
                                          <p>While optimizing your custom home theater with the latest smart home technology, we can do the same with your entire home through our home automation system services.
                                          </p>
                                          <Link className="read_more" to="/home-automation">Read More</Link>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div id="bo_ho" className="we_box text_align_center">
                                          <i><img src="images/service3.jpg" alt="#"/></i>
                                          <h3>Project<br/>Management</h3>
                                          <p>Building your dream smart home from scratch or through complete renovation? Our team has exceptional time <br/> management skills.</p>
                                          <Link className="read_more" to="/project-management">Read More</Link>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>                     
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="about">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="titlepage text_align_center">
                     <h2>About Company</h2>
                     <p>Welcome to AVSI, your premier destination for exceptional home theater and smart home services. With a passion for cutting-edge technology and a commitment to unparalleled customer satisfaction, we specialize in transforming houses into immersive entertainment havens and seamlessly integrated smart homes. Our dedicated team of experts combines expertise in audiovisual design, automation systems, and custom installations to deliver personalized solutions that exceed your expectations.
                        At AVSI, we understand that every client has unique preferences and requirements. That's why we offer tailor-made home theater designs that harmonize striking aesthetics, superior acoustics, and state-of-the-art equipment. From initial concept and meticulous planning to flawless execution, our team collaborates closely with you to bring your vision to life. Whether you desire a classic cinematic experience or a contemporary, cutting-edge setup, we ensure optimal audiovisual performance, immersive seating arrangements, and intelligent control systems that effortlessly enhance your entertainment enjoyment.
                        </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className="contact">
         <div className="container">
            <div className="row ">
               <div className="col-md-6">
                  <div className="titlepage text_align_left">
                     <h2>Get In Touch</h2>
                  </div>
                  <form action="https://formspree.io/f/xdoragdq" method="POST" id="request" className="main_form">
                     <div className="row">
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Name" type="type" name=" Name"/> 
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Phone Number" type="type" name="Phone Number"/>                          
                        </div>
                        <div className="col-md-12">
                           <input className="contactus" placeholder="Email" type="type" name="Email"/>                          
                        </div>
                        <div className="col-md-12">
                           <textarea className="textarea" placeholder="Message" type="type" Message="Name"></textarea>
                        </div>
                        <div className="col-md-12">
                           <button className="send_btn" type="submit" value="send">Send Now</button>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="col-md-6">
                  <div className="titlepage text_align_left">
                     <h2>Clients Comments</h2>
                  </div>
                  <div id="clientsl" className="carousel slide our_clientsl" data-ride="carousel">
                     <ol className="carousel-indicators">
                        <li data-target="#clientsl" data-slide-to="0" className="active"></li>
                        <li data-target="#clientsl" data-slide-to="1"></li>
                        <li data-target="#clientsl" data-slide-to="2"></li>
                     </ol>
                     <div className="carousel-inner">
                        <div className="carousel-item active">
                           <div className="container">
                              <div className="carousel-caption posi_in">
                                 <div className="clientsl_text">                                    
                                    <h3>Jacky <img src="images/icon.png" alt="#"/></h3>
                                    <p>AVSI LLC is very professional. Their work is top notch and very neat. I look forward to continue working together.</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="carousel-item">
                           <div className="container">
                              <div className="carousel-caption posi_in">
                                 <div className="clientsl_text">
                                    <h3>Donovan<img src="images/icon.png" alt="#"/></h3>
                                    <p>I thank AVSI LLC for being an honest and knowledgeable Company. They installed my surround sound, cctv and installed recess lights with smart switches, Connected to my amazon Alexa. I wasn't surprised how competent and knowledgeable there technicians are. 
                                    AVSI LLC came highly recommend. There technician Paul was awesome. I will definitely recommend them to anyone because they goes above and beyond to please the customer</p>
                                 </div>
                              </div>
                           </div>
                        </div>                        
                     </div>
                     <a className="carousel-control-prev" href="#clientsl" role="button" data-slide="prev">
                     <i className="fa fa-angle-left" aria-hidden="true"></i>
                     <span className="sr-only">Previous</span>
                     </a>
                     <a className="carousel-control-next" href="#clientsl" role="button" data-slide="next">
                     <i className="fa fa-angle-right" aria-hidden="true"></i>
                     <span className="sr-only">Next</span>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}

export default index
