const faqData = [
    {
      question: "What can a SmartHome do?",
      answer: "Do you want to control your home's security, monitor your children's gaming and tv time, lighting, temperature, from a handheld remote? SmartHome Systems are capable of shutting down your house from a single button press as you leave for work or close your drapes? All this and more is possible with today Smarthome's Crestron technology. Our sales team can give you virtual tours and help you “see” the possibilities are limited only by your imagination.",
    },
    {
      question: "There are so many choices how do I make know what is right for me?",
      answer: "AV System Integrators LLC works with hundreds of manufacturers in a range of price points, and combining that with your dream goals allows a professional proposal to be created for you. The sales team act as an impartial broker and based on your needs will help you choose the products that maximize your budget and create your individual plan.",
    },
    {
      question: "Why Choose AV System Integrators LLC for your SmartHome installation?",
      answer: <div><p>The installation of your SmartHome Crestron system (and others like it) is such a complicated matter but we make it easy for you. We begin each process with a conversation and we listen carefully and follow our Five-Step process. This step by step approach is a thorough way to make sure every need will be met. Each of the following areas will be discussed: Design, Wiring , Connection ,Equipment Installation & Programming and last will be the In-home Training for you, your family and staff.</p>
     <p>Each step of the way, you will help to define your custom package. The process makes certain that all details will be included no matter how small.</p>
     <p style={{fontWeight:"bold"}}>Step 1: Scope of Work, Estimate and Contract</p>
     <p>The team at AV System Integrators LLC believes that a detailed scope of work is the foundation of our company’s success in home automation. After discussing our client’s requirements and their vision, the scope of work is prepared. The budget, aesthetic preferences, functionality, energy efficiency and future technological needs will be clearly stated in the contract.</p>
     <p style={{fontWeight:"bold"}}>Step 2: Design & Documentation</p>
     <p>As your home automation designer we will design a low voltage plan around your new or remodel home</p>
     <p>Designing a SmartHome will include low voltage plans, whether you are building a new construction home or completing a renovation project. Every detail of the light locations will be laid out maximizing the exterior ambiance. Your wiring will be maximized with the most efficient plan which will include descriptions for TV, Phone, Data, Lighting and Audio Visual equipment. Once designed this becomes the roadmap which gives clear directions to all the tradesman.</p>
     <p style={{fontWeight:"bold"}}>Step 3: Cabling and Infrastructure Installation</p>
     <p>Cabling infrastructures as commonly known as structured wiring will include all the audio visual, communications and data plans and installation.</p>
     <p style={{fontWeight:"bold"}}>Step 4: Product Installation & Programming</p>
     <p>Installation of televisions, speakers, security, surveillance cameras, lighting controls, remotes and all corresponding equipment is installed during this process and everything will be programmed to integrate with all corresponding components.</p>
     <p style={{fontWeight:"bold"}}>Step 5: Training You, Your Family and your Staff</p>
     <p>AV System Integrators LLC #1 priority is to take the complicated and simplify. A SmartHome can be intimidating, but we will make sure you understand how to command it. There will be through training, and will repeat as necessary so that you feel comfortable. We understand that with new technology, there is learning curve.</p>
    </div>,  
    },
    {
      question: "I have a home theater, where should I upgrade my system? ",
      answer: "If you are happy with your picture and sound consider upgrading the cabling and power delivery. You would be surprised how much quality a system can lose due to poor power delivery. Electronic noise from our surroundings can be drastically diminished. Also, analog and digital devices suffer from different power issues. A quality power conditioner will take these into consideration and deliver specific “noise” suppression while delivering a reliable flow. Battery back-ups can also save you hundreds from power outages. Many entertainment devices need to cool down before completely shutting off. An unexpected power interruption can devastate you system for weeks and cost  much more than a preventative measure. Don’t think these devices are the same as power strips and backups at the corner store. Equipment only we can provide will deliver the protection you need. Choosing the wrong equipment can create more electronic noise than you began with. Not only will you hear the difference, but you can see it as well.",
    },
    {
      question: "Once my plan is designed who will install it? ",
      answer: <div className="ans">
        <p>The complexity of a home theater requires skill not only in construction, but also in integrating electronics. Home theater construction is a specialty, and there are many contractors that specialize in home theater design, remodeling and construction, but AV System Integrators LLC creates peace of mind for you, knowing that we have only the best installers. You are the best at what you do, and we are too. We love to begin at the beginning of the design phase, but we can help at whatever phase you are in. The Custom Electronic Design & Installation Association (CEDIA) wants you to choose wisely.</p>
        <p>“If you're ready to hire a home theater contractor, HGTV expert, Terri Ulick advises,[there] are a few criteria to consider:</p>
        <p><i class="bi bi-check-circle"></i><span>Knowledge of the latest home theater gear and technology</span></p>
        <p><i class="bi bi-check-circle"></i><span>Responsive to your planning ideas and requests</span></p>
        <p><i class="bi bi-check-circle"></i>Photos of installations and construction projects</p>
        <p><i class="bi bi-check-circle"></i><span>Networked with other contractors for room additions and other specialties</span></p>
        <p><i class="bi bi-check-circle"></i> <span>Member of professional association”</span></p>
      </div>,
    },
    // Add more questions and answers as needed
  ];
  
  export default faqData;
  