import React from 'react'

function index() {
  return (
    <div>
        <section id="service-details" class="service-details">
        <div class="container">
        <div class="row gy-5">
            <div class="col-lg-12 ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <img src="images/audioinstallations.jpg" alt="audioinstallations" class="img-fluid services-img"/>
            <h3 style={{textAlign:"center"}}>Audio Installations</h3>
            <p>
            When it comes to total immersion into your chosen media in your home theater and entertainment center, regular Dolby stereo just cannot compare to a Hi Fi surround sound audio system. There are a variety of audio speakers, amplifiers, subwoofers, and outdoor speakers we can install to give different impressions, both aesthetically and acoustically. These can be further enhanced with power conditioners.
            </p>
            <h4>Our list of Audio Installationss include:</h4>
            <ul>
                <li><i class="bi bi-check-circle"></i> <span>A neater presentation of the room, with no wires hanging out to an eyesore or to be tripped over</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Mounted speakers can be placed much further away from the main hub to accommodate larger home entertainment rooms</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Speakers that are not mounted can be moved and placed in any arrangement depending on your preference of sound direction and acoustic arrangement</span></li>
                <li><i class="bi bi-check-circle"></i> <span>No need to worry about worn-out wiring that may go bad from abuse over time</span></li>
            </ul>
            <p>
            A surround sound audio system is not just for your home theater. If you like to listen to music in another room or throughout the house, our audio installations can put in a speaker system that can be heard and controlled from anywhere in or out of the home. This arrangement is great for hosting parties that require ambient music or if you just like to jam while walking or working in the house. We can also install a PA system that can allow you to announce or speak to others in and around the house with our easy-to-use touch screen control interface.
            </p>
            <p>
            There are many styles of speakers to choose from depending on the ideal look you want for the room. Large tower speakers provide excellent sound quality and can be prominent showpieces that impress your friends and family. Another option is to keep all your equipment hidden for a less-cluttered look. The audio devices can be disguised as cabinets, walls, or other design elements. In outdoor areas, we can also provide “hidden” outdoor speakers that resemble rocks and other natural formations to maintain an aesthetic ambiance that's to your liking.
            </p>
            </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default index
